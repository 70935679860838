/* eslint-disable camelcase */
import React from "react";
import Markdown from "../../components/Common/Markdown";

import { ContactContainer, Title } from "./subtitle.styles";
import { SubtitleProps } from "./subtitle.types";
import { ContentWrapper } from "../../styles/globals/grid";

const Subtitle = ({ subtitle }: SubtitleProps) => {
  return (
    <ContactContainer>
      <ContentWrapper>
        <Title>
          <Markdown text={subtitle} textColor="redTitle" />
        </Title>
      </ContentWrapper>
    </ContactContainer>
  );
};

export default Subtitle;
