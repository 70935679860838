import styled from 'styled-components';
import { COLORS } from '../../styles/globals/colors';
import { TABLET_800, TABLET_768, DESKTOP_1500 } from '../../styles/globals/sizes';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  
  @media (max-width: ${TABLET_800}px) {
    flex-direction: column-reverse;
  }
  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  text-align: center;

  @media (max-width: ${TABLET_768}px) {
  }
`;

export const Title = styled.h2`
  display: flex;
  align-self: self-start;
  font: normal normal bold 37px/49px Roboto;
  color: ${COLORS.redTitle};
  text-align: left;

  @media (max-width: ${TABLET_768}px) {
    font: normal normal bold 30px/43px Roboto;
    text-align: left;
  }

  p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 100vw;
  }
`;

export const SubTitle = styled.h3`
  font: normal normal normal 18px/30px "Montserrat";
  margin-top: 30px;
  text-align: left;
  color: ${COLORS.white};

  @media (max-width: ${TABLET_768}px) {
    font: normal normal normal 19px/30px "Montserrat";
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    flex-direction: column;
  }
`;