/* eslint-disable default-case */
import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";

import {
  DESKTOP_1024,
  DESKTOP_1200,
  MOBILE_460,
  TABLET_992,
  DESKTOP_1920,
  MOBILE_320,
  TABLET_600,
} from "../../styles/globals/sizes";

export const HeroContainer = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${DESKTOP_1024}px) {
    height: fit-content;
  }

  @media (max-width: ${TABLET_992}px) {
    background: white;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px;
  }
`;

export const HeroPicture = styled.picture`
  .gatsby-image-wrapper img {
    top: 60px;
    @media (max-width: ${MOBILE_460}px) {
    top: 0;
  }
  }
  `;

export const InternalWrapper = styled.div`
  margin: 0;
  position: absolute;
  width: 100%;
  max-width: 1500px;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 90%;
    margin: 0;
    flex: 1;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 100%;
    flex: 1;
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 100%;
  }
`;

export const MobileDiv = styled.div`
  display: none;
  width: 100%;
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) 10% 45%/cover no-repeat`
      : `purple`};
  @media (max-width: ${TABLET_992}px) {
    height: 400px;
    display: block;
    background-position: 80%;
  }
  @media (max-width: 600px) {
    height: 350px;
    background-position: 80%;
  }
  @media (max-width: ${MOBILE_460}px) {
    background-position: 80% 45%;
  }
`;

export const HeroTextDiv = styled.div`
  box-sizing: border-box;
  margin: auto;

  @media (max-width: 1600px) {
    width: 90%;
  }

  @media (max-width: ${TABLET_992}px) {
    padding: 25px 40px 0;
    box-sizing: border-box;
  }
  @media (max-width: ${TABLET_600}px) {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    margin: 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin: 30px 20px 0;
    padding: 0;
  }
  @media (max-width: ${MOBILE_320}px) {
    margin: 20px;
  }
`;

export const Title = styled.h1`
  width: 7em;
  margin: 40px 0 20px;
  color: ${COLORS.redTitle};
  text-align: left;
  font: normal normal 500 57px/60px Roboto;
  color: #E0281A;
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }
  /* @media (max-width: ${DESKTOP_1200}px) {
    width: 30%;
    font: 500 calc(3vw)/ calc(3.5vw) "Roboto";
  } */
  @media (max-width: ${TABLET_992}px) {
    margin: 40px 0 10px;
  } 
  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 14px 0;
    font: normal normal normal 47px/54px Roboto;
    text-align: center;
    align-items: center;
  }

  @media (max-width: ${TABLET_600}px) {
    width: 75%;
    text-align: left;
  }
`;

export const Paragraph = styled.p`
  margin-top: 20px;
  color: ${COLORS.grayText};
  font: 400 26px/34px "Roboto";
  width: 35%;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 0 0 20px 0;
    font: normal normal normal 20px/28px Roboto;
    text-align: center;
    align-items: center;
  }
  @media (max-width: ${TABLET_600}px) {
    text-align: left;
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;
