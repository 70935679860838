import styled from 'styled-components';
import { COLORS } from '../../styles/globals/colors';
import { TABLET_800, TABLET_768, DESKTOP_1500 } from '../../styles/globals/sizes';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 30px 0;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column-reverse;
  }
  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  text-align: center;

  @media (max-width: ${TABLET_768}px) {
  }
`;

export const Title = styled.h2`
  font: normal normal 55px/60px mulish;
  color: ${COLORS.white};

  @media (max-width: ${TABLET_768}px) {
    font: normal normal 38px/43px mulish;
    text-align: left;
  }

  p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 100vw;
  }
`;

export const SubTitle = styled.p`
  font: normal normal normal 18px/26px Roboto;
  text-align: left;
  color: ${COLORS.gray};

  @media (max-width: ${TABLET_768}px) {
    font: normal normal normal 18px/26px Roboto;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    flex-direction: column;
  }
`;