/* eslint-disable camelcase */
import React from "react";
import Markdown from "../../components/Common/Markdown";

import { ContentWrapper } from "../../styles/globals/grid";

import {
  ContactContainer,
  TitleContainer,
  SubTitle,
  Wrapper,
} from "./paragraphHighlight.styles";
import { ParagraphHighlightProps } from "./paragraphHighlight.types";

const ParagraphHighlight = ({ text }: ParagraphHighlightProps) => {
  return (
    <ContactContainer>
      <ContentWrapper>
        <Wrapper>
          <TitleContainer>
            <SubTitle>
              <Markdown text={text} textColor="white" />
            </SubTitle>
          </TitleContainer>
        </Wrapper>
      </ContentWrapper>
    </ContactContainer>
  );
};

export default ParagraphHighlight;
