import styled, { css } from "styled-components";

import { DESKTOP_1024, DESKTOP_1280, TABLET_600, TABLET_992 } from "../../styles/globals/sizes";

export const FeatureSectionContainer = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ImageWrapper = styled.div`
  width: 488px;
  height: 474px;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  img {
    flex: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${TABLET_992}px) {
    position: static;
    margin-top: 30px;
  }

  @media (max-width: ${TABLET_600}px) {
    width: fit-content;
  }
`;

export const FeatureImageBody = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
  }

  @media (max-width: ${TABLET_600}px) {
    width: 100%;
  }
  
`;

export const FeatureImageTitle = styled.div`
  display: flex;
  font-size: 53px;
  line-height: 69px;
  margin-bottom: 30px;
  p {
    font-family: mulish !important;
    font-weight: 900 !important;
  }
  @media (max-width: ${DESKTOP_1280}px) {
    display: none;
  }
`;

export const FeatureImageTitleMobile = styled.div`
  font-size: 38px;
  line-height: 43px;
  font-weight: 900;
  margin-bottom: 30px;
  display: none;
  p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 90vw;
    font-family: mulish !important;
  }
  @media (max-width: ${TABLET_992}px) {
    display: flex;
  }
`;

export const FeatureImageDescription = styled.div`
  width: 50%;
  font: normal normal normal 18px/26px Roboto;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }
`;

interface IFeatureStylesProps {
  orientation?: string;
  hasCTA?: boolean;
}
