import React from "react";

import {
  HeroPicture,
  HeroContainer,
  InternalWrapper,
  Title,
  HeroTextDiv,
  PhoneAndParagraph,
  Paragraph,
} from "./GenericHero.styles";
import { HeroProps } from "./GenericHero.types";
import { Markdown, MediaRender } from "../../components/Common";
const GenericHeroSection = ({ title, description, image }: HeroProps) => {
  return (
    <HeroContainer>
      <HeroPicture>
        <MediaRender
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          mediaSource={image}
        />
      </HeroPicture>
      <InternalWrapper data-aos="fade">
        <HeroTextDiv>
          <Title>
            <Markdown text={title} textColor="redTitle" />
          </Title>
          <PhoneAndParagraph>
            <Paragraph>
              <Markdown text={description} textColor="grayText" />
            </Paragraph>
          </PhoneAndParagraph>
        </HeroTextDiv>
      </InternalWrapper>
    </HeroContainer>
  );
};

export default GenericHeroSection;
