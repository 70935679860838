import React from "react";

import { Markdown, MediaRender } from "../../components/Common";

import {
  ImageWrapper,
  FeatureImageBody,
  FeatureImageDescription,
  FeatureSectionContainer,
} from "./featureSection.styles";
import { FeatureImageProps } from "./featureSection.types";
import { ContentWrapper } from "../../styles/globals/grid";

const FeatureSection = ({ image, description }: FeatureImageProps) => {
  return (
    <FeatureSectionContainer>
      <ContentWrapper>
        <FeatureImageBody>
          <FeatureImageDescription>
            <Markdown textColor="gray" text={description} />
          </FeatureImageDescription>
          <ImageWrapper>
            <MediaRender mediaSource={image} />
          </ImageWrapper>
        </FeatureImageBody>
      </ContentWrapper>
    </FeatureSectionContainer>
  );
};

export default FeatureSection;
