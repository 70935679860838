/* eslint-disable camelcase */
import React from 'react';
import Markdown from '../../components/Common/Markdown';

import { ContactContainer, TitleContainer, Title, SubTitle, Wrapper } from './paragraph.styles';
import { ParagraphProps } from './paragraph.types';
import { ContentWrapper } from '../../styles/globals/grid';

const Paragraph = ({ text }: ParagraphProps) => {
  return (
    <ContactContainer>
      <ContentWrapper>
        <Wrapper>
          <TitleContainer>
            <SubTitle>
              <Markdown text={text} textColor="gray" />
            </SubTitle>
          </TitleContainer>
        </Wrapper>
      </ContentWrapper>
    </ContactContainer>
  );
};

export default Paragraph;
