import React, { FunctionComponent } from "react";

import { IStrapiProps } from "./types";

import Paragraph from "../../sections/Paragraph";
import FeatureSection from "../../sections/FeatureSection";
import ParagraphHighlight from "../../sections/ParagraphHighlight";
import Subtitle from "../../sections/Subtitle";

const normalizeTypename = (componentName: string) => {
  return componentName
    .replace(/cms_Component/g, "")
    .replace(/\./g, "")
    .replace(/-/g, "")
    .replace(/_/g, "")
    .toLowerCase();
};

const getComponentName = (component: any) => {
  const componentName = normalizeTypename(
    component.__typename || component.__component || ""
  );
  return componentName;
};
const ComponentSwitch: FunctionComponent<IStrapiProps> = ({ component }) => {
  switch (getComponentName(component)) {
    case "otherparagraph":
      return <Paragraph text={component.Paragraph_Section_Text} />;
    case "otherparagraphhighlight":
      return (
        <ParagraphHighlight text={component.Paragraph_Highlight_Section_Text} />
      );
    case "othersubtitle":
      return <Subtitle subtitle={component.Subtitle_text} />;
    case "paragraphfeaturedimage":
      return (
        <FeatureSection
          description={component.Feature_Paragraph}
          image={component.Feature_Image}
        />
      );
    default:
      return <div>{component.__typename}</div>;
  }
};

const BodyParser: FunctionComponent<IStrapiProps> = ({ component }) => (
  <ComponentSwitch component={component} />
);

export default BodyParser;
